import {
  useAppDispatch,
  useAppSelectorWithTypes,
} from '@/fsd/app/providers/ReduxStoreByOfficialDoc/hooks';
import { cartSliceActions, getCart } from '@/fsd/entities/cart';
import { useGetCartSum } from '@/fsd/shared/ui/Cart/hooks/useGetCartSum';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import { IconButton } from '@mui/material';

import { SendCartToKeyCrm } from '../../utils/SendCartToKeyCrm';
import { CustomCartItem } from './CustomCartItem';

const useStyle = {
  Button: {
    '&:hover': {
      boxShadow: 'lightcoral !important',
      color: 'red',
    },
    '&:active': {
      boxShadow: 'none !important',
      backgroundColor: 'lightcoral !important',
      color: 'unset',
    },
  },
};

export function RenderRows() {
  const cartRaw = useAppSelectorWithTypes(getCart);

  const sum = useGetCartSum();
  const dispatch = useAppDispatch();

  const emptyCart = () => {
    dispatch(cartSliceActions.emptyCart());
  };

  return (
    <div className='ContentWrapper'>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'end',
        }}
      >
        <IconButton
          aria-label='Remove Shopping Cart'
          onClick={emptyCart}
          sx={useStyle.Button}
        >
          <RemoveShoppingCartIcon />
        </IconButton>
      </div>
      {cartRaw.map((item, idx) => (
        <CustomCartItem key={idx} idx={idx} cartItem={item} />
      ))}
      <br />
      <h4>Сума {sum} ₴</h4>
      <SendCartToKeyCrm />
    </div>
  );
}
