import { SendCartToKeyCrm } from '../../utils/SendCartToKeyCrm';

export function RenderRows() {
  return (
    <div className='ContentWrapper'>
      <h1 style={{ textAlign: 'center' }}>Оформлення замовлення</h1>
      <SendCartToKeyCrm />
    </div>
  );
}
