import { TypeOffer } from '@/fsd/shared/types';

export class Offer {
  static PropertiesList({ offer }: { offer: TypeOffer }) {
    return offer.properties.map((prop, _idx) => (
      <span key={_idx}>
        {prop.name} {prop.value}
      </span>
    ));
  }
}
