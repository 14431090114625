import { useEffect, useState } from 'react';

import { useAppSelectorWithTypes } from '@/fsd/app/providers/ReduxStoreByOfficialDoc/hooks';
import { getCart } from '@/fsd/entities/cart';
import { useGetProductsByIdsQuery } from '@/fsd/entities/ProductFilters/model/services/fetchProductsList/RTKQProductsListApi';
import { TypeOffer } from '@/fsd/shared/types';
import {
  getOfferByProdIdInCart,
  getProductByProdIdInCart,
} from '@/fsd/shared/ui/Cart/cartUtils';
import { Button, CircularProgress, TextField } from '@mui/material';

function getObjListOfferProps({ offer }: { offer: TypeOffer | undefined }) {
  return offer?.properties.map((prop) => prop.name + ' ' + prop.value);
}

export function SendCartToKeyCrm() {
  const cartRaw = useAppSelectorWithTypes(getCart);
  // const cartProducts = useAppSelectorWithTypes(cartProductsSelectors.products);

  const ids = cartRaw.map((_) => _.item.product_id);
  const { currentData, isLoading } = useGetProductsByIdsQuery(ids);

  const [is1BtnDisabled, setIs1BtnDisabled] = useState<boolean>(false);
  const [data, setData] = useState<any | undefined>(undefined);
  const [loading, setLoading] = useState<boolean | undefined>(undefined);
  const [error, setError] = useState<object | undefined>(undefined);

  const [fullName, setFullName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [buyerComment, setBuyerComment] = useState<string>('');

  const USER = 'user';

  useEffect(() => {
    const user = localStorage.getItem(USER);
    if (user) {
      try {
        const objUser = JSON.parse(user);
        objUser?.buyer?.full_name && setFullName(objUser.buyer.full_name);
        objUser?.buyer?.phone && setPhone(objUser.buyer.phone);
        objUser?.buyer?.email && setEmail(objUser.buyer.email);
        // objUser?.buyer_comment &&
        //     setBuyerComment(objUser.buyer_comment);
      } catch (e) {
        console.error(e);
      } finally {
      }
    }
  }, []);

  const saveUserintoLocalStorage = () => {
    const objUser = {
      buyer: {
        full_name: fullName,
        phone: phone,
        email: email,
      },
      // buyer_comment: buyerComment,
    };

    localStorage.setItem(USER, JSON.stringify(objUser));
  };

  const getCartToKeyCRM = () => {
    let cartToKeyCRM = undefined;

    if (currentData) {
      const products: any = [];
      cartRaw.forEach((cartItem) => {
        const product = getProductByProdIdInCart(currentData.data, cartItem);

        if (product) {
          // const copy = { ...product };
          // delete copy.properties_agg;
          // delete copy.danceex_options;
          let sku = product?.sku;
          let comment =
            (!sku || sku.trim().length === 0 ? 'Відсутній артикул / ' : '') +
            product.name;

          if (cartItem.item?.offer_id) {
            const offer = getOfferByProdIdInCart(product, cartItem);
            sku = offer?.sku;
            comment =
              (!sku || sku.trim().length === 0 ? 'Відсутній артикул / ' : '') +
              product.name +
              ': ' +
              getObjListOfferProps({ offer })?.join('/');
          }

          const copy = {
            sku: sku,
            price: product.min_price,
            quantity: cartItem.qty,
            comment: comment,
          };
          products.push(copy);
          // prods.push({ ...copy, quantity: cartItem.qty });
        }
      });

      cartToKeyCRM = {
        // source_id: 1,
        // source_name: 'Instagram Danceex',
        source_id: 5,
        source_name: 'test.danceex.com.ua',
        products,
        buyer: {
          full_name: fullName,
          phone: phone,
          email: email,
        },
        buyer_comment: buyerComment,
      };

      setData(cartToKeyCRM);
    }

    console.log('cartRaws', cartRaw);
    console.log('cartToKeyCRM', cartToKeyCRM);
    return cartToKeyCRM;
  };

  const build = () => {
    if (
      fullName.trim().length == 0 ||
      phone.trim().length == 0
      // ||
      // email.trim().length == 0
    ) {
      alert("Будь-ласка, заповніть поля Ім'я, Телефон та email");
      return;
    }

    if (currentData) {
      const cartToKeyCRM = getCartToKeyCRM();

      if (cartToKeyCRM) {
        handleClick();
      } else {
        console.log('cartToKeyCRM is ', cartToKeyCRM);
        console.log('cartToKeyCRM not send to keyCRM');
      }

      function startLoading() {
        setIs1BtnDisabled(true);
        setLoading(true);
        setData(undefined);
        setError(undefined);
      }

      function handleClick() {
        startLoading();

        const CREATE_ORDER = '/backend/api/keyCRM/create_order.php';

        fetch(CREATE_ORDER, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
          body: JSON.stringify({
            order: cartToKeyCRM,
          }),
        })
          .then((res) => {
            return res.text();
          })
          .then((r) => {
            if ('' !== r) {
              const products = JSON.parse(r);
              setData(products);
              setError(undefined);
            }
          })
          .finally(() => {
            setIs1BtnDisabled(false);
            return setLoading(false);
          })
          .catch((_) => setError(_));
      }
    }
  };

  return (
    <div style={{ paddingTop: '40px' }}>
      <div style={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
        <TextField
          label="Ім'я"
          variant='outlined'
          size='small'
          onChange={(e) => {
            saveUserintoLocalStorage();
            setFullName(e.target.value);
          }}
          value={fullName}
        />
        <TextField
          label='Телефон'
          variant='outlined'
          size='small'
          onChange={(e) => {
            saveUserintoLocalStorage();
            setPhone(e.target.value.trim());
          }}
          value={phone}
        />
        <TextField
          label='email'
          variant='outlined'
          size='small'
          onChange={(e) => {
            saveUserintoLocalStorage();
            setEmail(e.target.value.trim());
          }}
          value={email}
        />
        <TextField
          label='Коментар'
          variant='outlined'
          size='small'
          onChange={(e) => {
            saveUserintoLocalStorage();
            setBuyerComment(e.target.value);
          }}
          value={buyerComment}
          multiline
          rows={3}
          // maxRows={4}
        />
      </div>

      {false && (
        <Button
          variant='outlined'
          onClick={getCartToKeyCRM}
          disabled={is1BtnDisabled}
          style={{ marginTop: '12px' }}
        >
          Показати корзину для відправки
        </Button>
      )}
      <Button
        variant='outlined'
        onClick={build}
        disabled={is1BtnDisabled}
        style={{ marginTop: '12px' }}
      >
        Створити замовлення
      </Button>
      {loading && <CircularProgress color='info' />}
      {isLoading && <CircularProgress color='success' />}
      {data && <pre>{JSON.stringify(data, undefined, 2)}</pre>}
      {error && (
        <pre style={{ color: 'red' }}>
          {JSON.stringify(error, undefined, 2)}
        </pre>
      )}
    </div>
  );
}
