import { TDanceexOptions } from '@/fsd/shared/types/scope/TypeProduct';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { IFiltersRTKQ } from '../../types/filtersRTKQType';

const baseUrl = import.meta.env.VITE_API_BASE_URL;

const ENDPOINT__UPDATE_PRODUCT_FILTERS = '/update_product_filters.php';
const ENDPOINT__UPDATE_PRODUCT_VISIBLE_STATUS =
  '/update_product_visible_status.php';

export const buildFiltersWithIds = (filtersRTKQAdmin: IFiltersRTKQ) => {
  const filtersToServer: any = {};
  for (const filter in filtersRTKQAdmin) {
    const ids = filtersRTKQAdmin[filter].values
      .filter((_: any) => _.checked)
      .map((_: any) => _.id);

    filtersToServer[filter] = ids;
  }

  return filtersToServer;
};

export const RTKQAdminApi = createApi({
  reducerPath: 'api/adminRequests',
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    updateProductFilters: builder.mutation<
      {} | undefined, // ResponseData, // return data type
      { id: any; filters: IFiltersRTKQ } // RequestData // post params
    >({
      // keepUnusedDataFor: 0,
      query: ({ id, filters }) => {
        const DTOFilters = buildFiltersWithIds(filters);
        const data = { id, filters: DTOFilters };

        return {
          url: ENDPOINT__UPDATE_PRODUCT_FILTERS,
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            something: 'xxx',
            'Content-Type': 'application/json; charset=UTF-8',
          },
        };
      },
    }),
    updateProductdanceexOptions: builder.mutation<
      {} | undefined, // ResponseData, // return data type
      { id: any; danceexOptions: TDanceexOptions } // RequestData // post params
    >({
      // keepUnusedDataFor: 0,
      query: ({ id, danceexOptions }) => {
        const data = { id, danceex_options: danceexOptions };

        return {
          url: ENDPOINT__UPDATE_PRODUCT_VISIBLE_STATUS,
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            something: 'xxx',
            'Content-Type': 'application/json; charset=UTF-8',
          },
        };
      },
    }),
  }),
});

export const {
  useUpdateProductFiltersMutation,
  useUpdateProductdanceexOptionsMutation,
} = RTKQAdminApi;
