import './css/header-top.scss';

import { MouseEvent, useState } from 'react';

import { Link } from 'react-router-dom';

import { getIsAdmin, MAX_WIDTH_TABLET } from '@/const';
import { useAppSelector } from '@/fsd/app/providers/ReduxStoreByOfficialDoc/hooks';
import { getCart } from '@/fsd/entities/cart';
import { RoutesNames } from '@/routes/RoutesName';
import { CorporateFare } from '@mui/icons-material';
import Logout from '@mui/icons-material/Logout';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  IconButton,
  ListItemIcon,
  MenuItem,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import Menu from '@mui/material/Menu';

import { MenuMobile } from '../../HeaderBottom/impl';
import { MobileFilters } from '../widgets/MobileFilters';

export const HeaderTop = () => {
  const isTablet = useMediaQuery(MAX_WIDTH_TABLET);
  const productsByCount = useAppSelector(getCart);
  const sumQty = productsByCount.reduce((acc, _) => (acc += _.qty), 0);

  const handleClick = (event: MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => setAnchorEl(null);

  return (
    <section aria-label='Top header content' className='Header-Top'>
      <div className='ContentWrapper '>
        <div className='Header-Wrapper'>
          <div className='Header-StoresWrapper'>
            <div style={{ display: 'flex', gap: '24px' }}>
              {/* <a href='/store-locator'>Магазини</a> */}
              {isTablet && <MenuMobile />}
            </div>
          </div>
          <div className='Header-SaleLink'>
            БЕЗКОШТОВНА ДОСТАВКА ПРИ ЗАМОВЛЕННІ ВІД 2500₴
          </div>
          <div
            className='Header-AccountLinksWrapper'
            style={{ display: 'flex', gap: '24px' }}
          >
            {isTablet && <MobileFilters />}
            <Link to={`/cart`}>
              <div
                className='MiniCart MiniCart_empty'
                style={{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <div className='MiniCart-Icon' style={{ position: 'relative' }}>
                  <svg>
                    <use href='images/icons/mini-cart.svg#icon'></use>
                  </svg>
                  <div aria-label='Minicart Badge' className='MiniCart-Badge'>
                    {sumQty}
                  </div>
                </div>
              </div>
            </Link>
            {getIsAdmin() && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'center',
                    ml: 'auto',
                  }}
                >
                  <Tooltip title='Account settings'>
                    <IconButton
                      onClick={handleClick}
                      size='small'
                      aria-controls={open ? 'account-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                    >
                      <div>
                        <MoreVertIcon sx={{ color: 'white' }} />
                      </div>
                    </IconButton>
                  </Tooltip>
                </Box>

                <Menu
                  anchorEl={anchorEl}
                  id='account-menu'
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <Link to={RoutesNames.KeyCRM} className='flex'>
                    <MenuItem>
                      <ListItemIcon>
                        <CorporateFare fontSize='small' />
                      </ListItemIcon>
                      Обробка KeyCRM даних
                    </MenuItem>
                  </Link>

                  <a href='/backend/api/admin/logout.php'>
                    <MenuItem>
                      <ListItemIcon>
                        <Logout fontSize='medium' style={{ fill: 'black' }} />
                      </ListItemIcon>
                      Вихід
                    </MenuItem>
                  </a>
                </Menu>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
